import { ElementTypeMap, ElementTypes } from '@/context/editor.context';
import { useEditor } from '@/hooks';

export const ElementsMenu = () => {
  const { selectedElement, focusedElement, setElementType, onRemoveElement } =
    useEditor();

  const square =
    selectedElement === 'SQUARE'
      ? 'w-9 h-9 border-green-600'
      : 'w-7 h-7 border-red-600';

  const rectangle =
    selectedElement === 'RECTANGLE'
      ? 'w-11 h-9 border-green-600'
      : 'w-9 h-7 border-red-600';

  const circle =
    selectedElement === 'CIRCLE'
      ? 'w-9 h-9 border-green-600'
      : 'w-7 h-7 border-red-600';

  const onSelectElement = (type: ElementTypeMap) => {
    if (focusedElement) onRemoveElement(focusedElement?.id);

    setElementType(type);
  };

  return (
    <div className="flex absolute w-fit h-fit bg-gray-300 top-0 left-0 bottom-0 right-0 z-50 mx-auto mt-2 rounded-md box-border px-4 py-2 [&>*]:mr-4 [&>*:last-child]:mr-0">
      <button
        className={`transition-all duration-300 border-2 ${square}`}
        onClick={() => onSelectElement(ElementTypes.SQUARE)}
      />

      <button
        className={`transition-all duration-300 border-2 rounded-full ${circle}`}
        onClick={() => onSelectElement(ElementTypes.CIRCLE)}
      />

      <button
        className={`transition-all duration-300 border-2 ${rectangle}`}
        onClick={() => onSelectElement(ElementTypes.RECTANGLE)}
      />
    </div>
  );
};

export default ElementsMenu;
