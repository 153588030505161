import { memo, ReactElement, useRef } from 'react';
import { Vector3 } from '@react-three/fiber';
import { useEditor } from '@/hooks';

import { Mesh } from 'three';

export interface SquareProps {
  position: Vector3;
  id: string;
  width: number;
  height: number;
  color: string;
}

const Square = memo<SquareProps>(
  ({ position, id, height, width, color }): ReactElement => {
    const meshRef = useRef<Mesh>(null);

    const { onFocusElement } = useEditor();

    return (
      <mesh
        ref={meshRef}
        position={position}
        onClick={() => onFocusElement(id, true)}>
        <planeGeometry args={[width, height]} />

        <meshStandardMaterial color={color} />
      </mesh>
    );
  },
);

export default Square;
