import { createContext, PropsWithChildren, useState } from 'react';
import { Vector3 } from '@react-three/fiber';
import { Vector3 as Vector } from 'three';
import { v4 as uuid } from 'uuid';

type OverridePositionField = Partial<
  Omit<Element, 'position' | 'size'> & {
    position: Vector;
    size: { width?: number; height?: number };
  }
>;

export type ElementTypeMap = 'CIRCLE' | 'RECTANGLE' | 'SQUARE';

export enum ElementTypes {
  CIRCLE = 'CIRCLE',
  RECTANGLE = 'RECTANGLE',
  SQUARE = 'SQUARE',
}

export interface Element {
  id: string;
  color?: string;
  position: Vector3;
  type: ElementTypeMap;
  size?: [number, number];
}

export interface IEditorContext {
  isEdit: boolean;
  rects: Partial<DOMRect>;
  elements: Element[];
  focusedElement?: Element;
  selectedElement: ElementTypeMap | null;
  setRects: (size: DOMRect) => void;
  onInsertElement: (position: Vector) => void;
  onFocusElement: (id: string, edit?: boolean) => void;
  setElementType: (element: ElementTypeMap) => void;
  onRemoveElement: (id: string) => void;
  onUpdateElement: (id: string, data: OverridePositionField) => void;
  removeFocusedElement: () => void;
}

const EditorContext = createContext({} as IEditorContext);

export const EditorProvider = ({ children }: PropsWithChildren) => {
  const [elements, setElements] = useState<Element[]>([]);
  const [rects, setRects] = useState<Partial<DOMRect>>({});
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [elementType, setElementType] = useState<ElementTypeMap | null>(null);
  const [focusedElement, setFocusedElement] = useState<Element | undefined>(
    undefined,
  );

  const onInsertElement = ({ x, y, z }: Vector) => {
    if (!elementType) return;

    const id = uuid();

    let size: [number, number];

    switch (elementType) {
      case 'CIRCLE':
        size = [3, 32];
        break;
      case 'RECTANGLE':
        size = [8, 5];
        break;
      default:
        size = [5, 5];
    }

    const element: Element = {
      id,
      size,
      color: 'red',
      position: [x, y, z],
      type: elementType,
    };

    setElements(prev => [...prev, element]);

    setElementType(null);
    setFocusedElement(element);
  };

  const onSelectElement = (target: ElementTypeMap) => {
    if (target === elementType) {
      setElementType(null);
    } else {
      setElementType(target);
    }
  };

  const onRemoveElement = (id: string) => {
    const filteredList = elements.filter(
      ({ id: elementId }) => elementId !== id,
    );

    setElements(filteredList);

    if (focusedElement?.id === id) {
      setFocusedElement(undefined);
      setIsEdit(false);
    }
  };

  const onUpdateElement = (id: string, data: OverridePositionField) => {
    const index = elements.findIndex(({ id: elId }) => elId === id);

    if (index === -1) return;

    setElements(all => {
      const { position, size = {} } = data;
      const current = all[index];

      for (const field in data) {
        switch (field) {
          case 'position':
            const { x, y, z } = position as Vector;

            const [currentX, currentY, currentZ] =
              current.position as Array<any>;

            current.position = [x || currentX, y || currentY, z || currentZ];
            break;
          case 'size':
            const { width, height } = size;

            current.size = [
              width || current?.size?.[0] || 0,
              height || current?.size?.[1] || 0,
            ];
            break;
          default:
            (current as any)[field] = data[field as keyof Element];
            break;
        }
      }

      all[index] = current;

      return [...all];
    });
  };

  const onFocusElement = (id: string, edit?: boolean) => {
    const element = elements.find(({ id: elId }) => elId === id);

    if (!element) return;

    setFocusedElement(element);

    if (edit) setIsEdit(true);
  };

  const removeFocusedElement = () => {
    setFocusedElement(undefined);
    setIsEdit(false);
  };

  const value: IEditorContext = {
    rects,
    isEdit,
    elements,
    focusedElement,
    selectedElement: elementType,
    setRects,
    onFocusElement,
    onUpdateElement,
    onInsertElement,
    onRemoveElement,
    removeFocusedElement,
    setElementType: onSelectElement,
  };

  return (
    <EditorContext.Provider value={value}>{children}</EditorContext.Provider>
  );
};

export default EditorContext;
