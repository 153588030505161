import Layout from '@/components/ui/Layout';

import Canvas from '@ui/Canvas';
import { ReactElement } from 'react';

const EditorPage = (): ReactElement => {
  return (
    <Layout>
      <Canvas />
    </Layout>
  );
};

export default EditorPage;
