import EditorPage from '@/pages/editor';
import { Route, Routes as Router } from 'react-router-dom';

const Routes = () => {
  return (
    <Router>
      <Route path="/" element={<EditorPage />} />
    </Router>
  );
};

export default Routes;
