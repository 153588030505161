import { Texture, TextureLoader } from 'three';
import { useLoader } from '@react-three/fiber';

import TestImage from '@/assets/images/image-editor.png';
import { useEditor } from '@/hooks';

const ImageBackground = () => {
  const texture = useLoader(TextureLoader, TestImage);

  const {
    rects: { height, width },
    onInsertElement,
  } = useEditor();

  return (
    <mesh onClick={({ point }) => onInsertElement(point)}>
      <planeGeometry args={[width, height]} />
      <meshBasicMaterial map={texture as Texture} />
    </mesh>
  );
};

export default ImageBackground;
