import { PropsWithChildren, ReactElement } from 'react';

import Header from '@ui/Header';

const Layout = ({ children }: PropsWithChildren): ReactElement => {
  return (
    <>
      <Header />

      <main className="w-full h-full bg-gray-200 relative">{children}</main>
    </>
  );
};

export default Layout;
