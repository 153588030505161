import { useThree } from '@react-three/fiber';
import { MapControls } from '@react-three/drei';

const Controls = () => {
  const {
    camera,
    gl: { domElement },
  } = useThree();

  const onChange = ({ target: { target } }: any) => {
    const { zoom, position } = camera;

    const limit = { y: 10 * zoom, x: 30 * zoom };

    if (target.y < -limit?.y) {
      target.y = -limit?.y;
      position.y = -limit?.y;
    } else if (target.y > limit?.y) {
      target.y = limit?.y;
      position.y = limit?.y;
    }

    if (target.x < -limit?.x) {
      target.x = -limit?.x;
      position.x = -limit?.x;
    } else if (target.x > limit?.x) {
      target.x = limit?.x;
      position.x = limit?.x;
    }
  };

  return (
    <MapControls
      enableRotate={false}
      enablePan
      enableZoom
      enableDamping
      screenSpacePanning
      onChange={onChange}
      target={[0, 0, 0]}
      minZoom={4}
      dampingFactor={0.25}
      keyPanSpeed={1}
      camera={camera}
      maxDistance={300}
      domElement={domElement}
    />
  );
};

export default Controls;
