import { ReactElement } from 'react';

import Controls from '@ui/Canvas/Controls';
import CanvasContainer from '@ui/Canvas/CanvasContainer';
import ImageBackground from '@ui/Canvas/ImageBackground';
import { EditorProvider } from '@/context/editor.context';

import ElementsMenu from '../ElementsMenu';
import Elements from './Elements';
import ElementMenu from '../ElementMenu';

const Canvas = (): ReactElement => {
  return (
    <EditorProvider>
      <ElementsMenu />

      <ElementMenu />

      <CanvasContainer>
        <ambientLight intensity={1} />

        <pointLight position={[10, 10, 10]} intensity={1} />

        <directionalLight position={[-5, 5, 5]} intensity={1} />

        <Controls />

        <ImageBackground />

        <Elements />
      </CanvasContainer>
    </EditorProvider>
  );
};

export default Canvas;
