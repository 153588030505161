import Providers from '@/providers';

import Routes from '@/routes';

const App = () => {
  return (
    <Providers>
      <Routes />
    </Providers>
  );
};

export default App;
