import { ReactElement, ReactNode } from 'react';
import { useEditor } from '@/hooks';

import Square from './Square';
import Circle from './Circle';
import Rectangle from './Rectangle';
import { Element } from '@/context/editor.context';

const Elements = (): ReactElement => {
  const { elements } = useEditor() as any;

  return elements?.map(
    ({
      type,
      position,
      id,
      color,
      size: [width, height] = [] as any,
    }: Element) => {
      switch (type) {
        case 'CIRCLE':
          return (
            <Circle
              key={id}
              position={position}
              id={id}
              radius={width}
              segment={height}
              color={color!}
            />
          );
        case 'RECTANGLE':
          return (
            <Rectangle
              key={id}
              position={position}
              id={id}
              height={height}
              width={width}
              color={color!}
            />
          );
        case 'SQUARE':
          return (
            <Square
              key={id}
              position={position}
              id={id}
              height={height}
              width={width}
              color={color!}
            />
          );
        default:
          return null as ReactNode;
      }
    },
  );
};

export default Elements;
