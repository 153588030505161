import { useState } from 'react';
import { useEditor } from '@/hooks';

import ElementDataForm from './ElementDataForm';
import ElementCustomizeForm from './ElementCustomizeForm';

import { ReactComponent as CloseIcon } from '@/assets/icons/close.icon.svg';

type ScreenMap = 'DATA' | 'CUSTOM';

enum screens {
  DATA = 'DATA',
  CUSTOM = 'CUSTOM',
}

const ElementMenu = () => {
  const [screen, setScreen] = useState<ScreenMap>(screens.DATA);

  const {
    focusedElement: { id } = {},
    isEdit,
    onRemoveElement,
    removeFocusedElement,
  } = useEditor();

  const menu = id === undefined ? '-translate-x-full' : 'translate-x-0';

  const focusedScreen = (target: ScreenMap) => {
    const base =
      'w-fit h-fit font-bold box-border p-1 border-2 rounded-lg transition-all duration-300';

    if (screen === target) {
      return `${base} border-blue-300 text-blue-600 bg-blue-100`;
    }

    return `${base} border-gray-300 text-gray-600`;
  };

  const onClose = () => {
    if (!isEdit) {
      onRemoveElement(id as any);
    } else {
      removeFocusedElement();
    }
  };

  return (
    <aside
      className={`absolute w-full h-svh flex flex-col box-border p-4 transition-all duration-500 bg-white z-50 max-w-sm ${menu}`}>
      <div className="w-full h-fit flex items-center border-b border-b-gray-100 box-border pb-3 mb-5">
        <div className="flex w-fit h-fit ml-auto [&>*]:mr-2 [&>*:last-child]:mr-0">
          <button
            className={focusedScreen(screens.DATA)}
            onClick={() => setScreen(screens.DATA)}>
            Dados
          </button>

          <button
            className={focusedScreen(screens.CUSTOM)}
            onClick={() => setScreen(screens.CUSTOM)}>
            Personalizar
          </button>
        </div>

        <button
          className="w-fit h-fit flex ml-auto"
          aria-label="Fechar Menu"
          onClick={onClose}>
          <CloseIcon className="w-8 h-8 fill-gray-700" />
        </button>
      </div>

      {screen === screens.DATA && <ElementDataForm />}

      {screen === screens.CUSTOM && <ElementCustomizeForm />}

      <div className="w-fit h-fit flex mt-10 mx-auto">
        <button
          type="button"
          onClick={removeFocusedElement}
          className="bg-green-500 text-white font-bold flex box-border px-6 py-2 w-fit h-fit rounded-lg shadow-md mx-auto mr-4">
          Concluído
        </button>

        {isEdit && (
          <button
            onClick={() => onRemoveElement(id as any)}
            className="bg-red-500 text-white font-bold flex box-border px-6 py-2 w-fit h-fit rounded-lg shadow-md mx-auto">
            Remover
          </button>
        )}
      </div>
    </aside>
  );
};

export default ElementMenu;
