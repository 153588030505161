import { PropsWithChildren, ReactElement, useEffect, useRef } from 'react';
import { Canvas as CanvasElement } from '@react-three/fiber';
import { useEditor } from '@/hooks';

const CanvasContainer = ({ children }: PropsWithChildren): ReactElement => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const { setRects } = useEditor();

  useEffect(() => {
    if (!canvasRef?.current) return;

    const rects = canvasRef.current.getBoundingClientRect() || {};

    setRects(rects);
  }, []);

  return (
    <CanvasElement
      ref={canvasRef}
      orthographic
      camera={{ zoom: 50, position: [0, 0, 100] }}>
      {children}
    </CanvasElement>
  );
};

export default CanvasContainer;
