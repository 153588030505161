import { memo, ReactElement, useRef } from 'react';
import { Vector3 } from '@react-three/fiber';
import { useEditor } from '@/hooks';

import { Mesh } from 'three';

export interface CircleProps {
  position: Vector3;
  id: string;
  radius: number;
  segment: number;
  color: string;
}

const Circle = memo<CircleProps>(
  ({ position, id, radius, segment, color }): ReactElement => {
    const meshRef = useRef<Mesh>(null);

    const { onFocusElement } = useEditor();

    return (
      <mesh
        ref={meshRef}
        position={position}
        onClick={() => onFocusElement(id, true)}>
        <circleGeometry args={[radius, segment]} />

        <meshStandardMaterial color={color} />
      </mesh>
    );
  },
);

export default Circle;
