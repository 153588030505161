import { useEffect, useRef } from 'react';
import { useEditor } from '@/hooks';

import { HexColorPicker } from 'react-colorful';

const ElementCustomizeForm = () => {
  const inputRef = useRef<HTMLInputElement>(null);

  const { focusedElement: { id, type, color } = {}, onUpdateElement } =
    useEditor();

  useEffect(() => {
    if (!inputRef?.current) return;

    inputRef.current.value = '';
  }, []);

  const onSizeChange = (value: number) => {
    switch (type) {
      case 'CIRCLE':
        onUpdateElement(id!, {
          size: { width: value, height: value + 10 },
        });
        break;
      case 'RECTANGLE':
        onUpdateElement(id!, {
          size: { width: value * 1.7, height: value },
        });
        break;
      case 'SQUARE':
        onUpdateElement(id!, {
          size: { width: value, height: value },
        });
        break;
    }
  };

  return (
    <form className="flex flex-col animate-in fade-in-20 duration-1000 [&>*]:mb-4 [&>*:last-child]:mb-0">
      <label htmlFor="size" className="!mb-1 ml-2">
        Tamanho
      </label>

      <input
        ref={inputRef}
        id="size"
        type="number"
        className="border border-gray-200 box-border pl-3"
        placeholder="Tamanho"
        onChange={({ target: { value } }) => onSizeChange(parseFloat(value))}
      />

      <label htmlFor="color-picker" className="!mb-2 ml-2">
        Cor
      </label>

      <HexColorPicker
        id="color-picker"
        onChange={color => onUpdateElement(id!, { color })}
        color={color}
      />
    </form>
  );
};

export default ElementCustomizeForm;
